.cartIconContainer img {
    width: 48px;
    height: 48px;
    border-bottom: double medium #e5e3d6; 
    border-top: double medium #e5e3d6;
    border-right: double medium #e5e3d6;
    margin-top: 2px;
}

.cartIconContainer {
    /* display: flex;
    position: relative;
    position: fixed; */
    left: 30px;
    bottom: 20px;
    position: fixed;
    /* z-index: 3000; */
    cursor: pointer;

}
