.menuCategories {
    position: relative;
    margin-top: 10%;
    margin-bottom: 10%;
    
}

.menuCategories a {
    font-size: 16px;
    font-weight: 400;
    color: #6cbb3c;
    text-decoration: none;
}

.menuCategories a:hover {
    color: #038f18;
    font-weight: 500;
  }
  
  /* .menuCategories a:active {
    color: #449213;
    text-decoration: underline;
  } */

  .menuCategories .active {
    color: #449213;
    text-decoration: underline;
    font-weight: 600;
  }
