.App {
  text-align: center;
}

.body-container {
  border: double medium #e5e3d6;
  border-top: 0;
  margin: 20px 30px 80px;
  padding: 0 0 30px;
  overflow: hidden;
}

