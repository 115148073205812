.menuItemContainer {
    position: relative;
}

.menuItem {
    margin: 20px;
    padding: 20px;
    margin-top: 10%;
    margin-bottom: 10%;
    border-bottom:1px solid #101011;
}

@media screen and (min-width: 1024px) {     /*  inline-flex only on bigger screens */
    .menuItem {
        display:inline-flex;
    }

}

.menuImage {
    height: 200px;
    object-fit: contain;
}

@media screen and (max-width: 1024px) { 
    .menuImage {
        height: 100px;
        object-fit: contain;
    }
}

.menuItemDescription {
    margin-left: 20px;
}
