/* add to cart button */
.addToCartButton {
    cursor: pointer;
    color: #000000;
    border: 1px solid #6cbb3c;  
    font-weight: 700;
    border-width: 1px;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: rgba(255, 255, 255, 0.5); 
    padding-bottom: 0.5rem;
}

.addToCartButton:active {
    color: #e6e6e6;
    background-color: #6cbb3c;
}

/* plus button */
.plusButton {
    cursor: pointer;
    font-size: large;
    color: #000000;
    border: 1px solid #6cbb3c;  
    background-color: rgba(255, 255, 255, 0.5); 
}

.plusButton:active {
    color: #e6e6e6;
    background-color: #6cbb3c;
}

/* minus button */
.minusButton {
    cursor: pointer;
    font-size: large;
    color: #000000;
    border: 1px solid #f7602e;  
    background-color: rgba(255, 255, 255, 0.5); 
}

.minusButton:active {
    color: #e6e6e6;
    background-color: #f9561b;
}

/* qty button */
.qtyButton {
    font-size: large;
    background-color: rgba(255, 255, 255, 0.5); 
}
