/* Base styles for the contact container */
.contact-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 30px;
    font-family: Arial, sans-serif;
  }
  
  /* Style for headings */
  .contact-container h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-container h2 {
    font-size: 1.8em;
    color: #555;
    margin-bottom: 15px;
  }
  
  /* Style for contact info section */
  .contact-info {
    margin-bottom: 30px;
  }
  
  .contact-details p {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
  }
  
  /* Style for the contact form */
  .contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    font-size: 1em;
    color: #333;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    font-size: 1em;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .form-submitted {
    font-size: 1.2em;
    color: #28a745;
    text-align: center;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .contact-container {
      padding: 15px;
    }
  
    .contact-container h1 {
      font-size: 1.8em;
    }
  
    .contact-container h2 {
      font-size: 1.5em;
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 0.9em;
    }
  
    .contact-form button {
      font-size: 0.9em;
      padding: 8px 12px;
    }
  }
  