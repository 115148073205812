.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }

.cartTitle {
    font-weight: 600;
    color: #6cbb3c;
  }

  ul {
    list-style: none;
  }

  /* place order button */
.placeOrderButton {
  cursor: pointer;
  color: #000000;
  border: 1px solid #6cbb3c;  
  font-weight: 700;
  border-width: 1px;
  border-radius: 0.2vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  background-color: rgba(255, 255, 255, 0.5); 
  padding-bottom: 0.5rem;
  width: fit-content;
  align-self: center;
}

.placeOrderButton:active {
  color: #e6e6e6;
  background-color: #6cbb3c;
}

/*checkout button */
.checkoutButton {
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #6cbb3c;  
  font-weight: 700;
  border-width: 1px;
  border-radius: 0.2vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  background-color: #54db00;
  padding-bottom: 0.5rem;
  width: fit-content;
  align-self: center;
}

.checkoutButton:active {
  background-color: #44b001; 
}

.checkoutButton:disabled, .placeOrderButton:disabled {
  background-color: gray;
  cursor: auto;
}

input {
  background: #dcdcdc;
  outline: none;
  border: 2px solid rgba(255, 255, 255, .2);
  border-radius: 40px;
  font-size: 16px;
  color: #000000;
  margin: 20px;
  padding: 5px;
}

input::placeholder {
  color: #181818;
}
  