.banner {
    width: 100vw;
    height: 80vh;
    object-fit: contain;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    color: white;
    font-family: 'Gill Sans';
}

.banner_contents {
    animation: drop-in 1200ms ease 500ms backwards;
   }
   
   @keyframes drop-in {
    from {
     opacity: 0;
     transform: translateY(100px);
    }
}


@media screen and (max-width: 1024px) {     /*  image will cover half screen height in smaller devices */
    .banner {
        width: 100vw;
        height: 50vh;
        object-fit: cover;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }
    .banner_contents {
        padding-right: 10vw;
        padding-top: 20vh;
    }
    
}

@media screen and (min-width: 1024px) {     /*  Altering text sizes in smaller devices */
    .banner_contents {
        font-size: 3rem;
        font-weight: 800;
        padding-top: 30vh;
        padding-bottom: 0.3rem; 
    }

}